import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes, Switch, Link } from "react-router-dom";
import Documentdetails from './components/DocumentDetails.js';
import Landing from './LandingPage.js';
import Processflow from './Processflow.js'; //Home Page

import Overallresult from './components/nestedFolder/OverallResult.js';
import VerifierDashboard from './components/nestedFolder/VerifierDashboard/VerifierDashboard.js';



class App extends Component{
  render() {
    return (
      
      <Router>
      <Routes>
        {/* {<Route path="/" element= {<Processflow />} />}*/}
      <Route path="/*" element= {<Landing />} /> 
      <Route path="/components/*" element= {<Documentdetails />}/> 
      <Route path="/components/nestedFolder/*" element= {<Overallresult />}/>
      <Route path="/components/nestedFolder/VeriferDashboard/*" element= {<VerifierDashboard />}/>
      
      
        </Routes>
    </Router>
    
    );
  }
}

export default App;