import React, { useState, useEffect } from 'react';
import FileViewer from 'react-file-viewer';
import '../index.css';

const S3FilePreview = ({ fileName, bucketName }) => {
  const [fileUrl, setFileUrl] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  
  const getFileExtension = (fileName) => {
  return fileName.split('.').pop().toLowerCase();
};

  useEffect(() => {
    // Assuming your S3 bucket and files are configured to be publicly accessible
    const publicUrl = `https://aimlusecasesv1.s3.amazonaws.com/${fileName}`;
    setFileUrl(publicUrl);
    setFileExtension(getFileExtension(fileName));
  }, [fileName, bucketName]);
if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
  console.log("IF CONDITION")
    return <img src={fileUrl} alt="Document Preview" width="100%" height="50%" />;
  } else{
    console.log("ELSE CONDITION")
  return (
    <div className="image-container" style={{height:'700px'}}>
    
      {fileUrl && (
        <FileViewer
          key={fileUrl} // Add this key to force rerender
          fileType={getFileExtension(fileName)}
          filePath={fileUrl}
          className="custom-file-viewer"
        />
      )}
    </div>
  );
  }
};



export default S3FilePreview;
