export const config = {
  full_diagnosis_info: "Full Diagnosis Information",
  FullDiagnosisInfo: "Full Diagnosis Information",
  Full_Diagnosis_Info: "Full Diagnosis Information",
  FULLDIAGNOSISINFO: "Full Diagnosis Information",
  fulldiagnosisinfo: "Full Diagnosis Information",
  
  diagnosis_info: "Diagnosis Information",
  DiagnosisInfo: "Diagnosis Information",
  Diagnosis_Info: "Diagnosis Information",
  DIAGNOSISINFO: "Diagnosis Information",
  diagnosisinfo: "Diagnosis Information",
  
  insured_info: "Insured Information",
  Insured_Info: "Insured Information",
  insuredinfo: "Insured Information",
  INSURED_INFO: "Insured Information",
  INSUREDINFO: "Insured Information",
  
  facility_info: "Facility Information",
  facilityinfo: "Facility Information",
  "facility info": "Facility Information",
  Facility_Info: "Facility Information",
  FACILITYINFO: "Facility Information",
  
  patient_info: "Patient Information",
  patientinfo: "Patient Information",
  "patient info": "Patient Information",
  Patient_Info: "Patient Information",
  PATIENTINFO: "Patient Information",
  
  procedureservicestable_info: "Procedure Services Table Information",
  procedure_services_table_info: "Procedure Services Table Information",
  procedureservicestableinfo: "Procedure Services Table Information",
  Procedureservicestable_Info: "Procedure Services Table Information",
  PROCEDURESERVICESTABLE_INFO: "Procedure Services Table Information",
  
  illness_info: "Illness Information",
  illnessinfo: "Illness Information",
  "illness info": "Illness Information",
  Illness_Info: "Illness Information",
  ILLNESSINFO: "Illness Information",
  
  federal_info: "Federal Information",
  federalinfo: "Federal Information",
  "federal info": "Federal Information",
  Federal_Info: "Federal Information",
  FEDRALINFO: "Federal Information",
  
  charges_info: "Charges Information",
  chargesinfo: "Charges Information",
  "charges info": "Charges Information",
  "CHARGES INFO": "Charges Information",
  Charges_Info: "Charges Information",
  CHARGESINFO: "Charges Information",
  GIVEFIRSTDATE: "Give First Date",
  
  total_extracted_data: "Total Extracted Data",
  totalextracteddata: "Total Extracted Data",
  "total extracted data": "Total Extracted Data",
  "TOTAL EXTRACTED DATA": "Total Extracted Data",
  Total_Extracted_Data: "Total Extracted Data",
  TOTALEXTRACTEDDATA: "Total Extracted Data",
  
  full_procedure_info: "Full Procedure Information",
  fullprocedureinfo: "Full Procedure Information",
  "full procedure info": "Full Procedure Information",
  Full_Procedure_Info: "Full Procedure Information",
  FULL_PROCEDURE_INFO: "Full Procedure Information",
  
  
  
  INSUREDGENDER: "Insured Gender",
  INSURANCEPROVIDER: "Insurance Provider",
  INSUREDNUMBER: "Insured Number",
  INSUREDNAME: "Insured Name",
  INSUREDPOLICYGROUPORFECANUM: "Insured Policy Group Or Feca Number",
  INSUREDPOLICYGROUPORFECANUMBER:	"Insured Policy Group Or Feca Number",
  INSUREDPOLICYGROUPFECANO: "Insured Policy Group Or Feca Number",
  insuredPolicyGroup: "Insured Policy Group Or Feca Number",
  INSURANCEPLANNAMEPROGRAM: "Insurance Plan Name Program",
  OTHERINSURENDPLAN: "Other Insured Plan",
  INSUREDPOLICYGROUPNUMBER: "Insured Policy Group Number",
  insurancePlanNameOrProgramName: "Insurance Plan Name Or Program Name",
  anyOtherHealthBenefitPlan: "Any Other Health Benefit Plan",
  nameAndAddressOfFacilityWhereServicesWereRendered: "Name And Address Of Facility Where Services Were Rendered",
  PATIENTRELATIONSHIPTOININSURED: "Patient Relationship To Insured",
  PATIENTRELATIONTOINSURED: "Patient Relationship To Insured",
  RELATIONTOINSURED: "Patient Relationship To Insured",
  INSUREDADDRESS: "Insured Address",
  INSUREDDATEOFBIRTH: "Insured Date Of Birth",
  INSUREDDATEOFBIRTH: "Insured Date Of Birth",
  FIRSTDATEOFILLNESS: "First Date Of Illness",
  firstDateOfSimilarIllness: "First Date Of Illness",
  DATEOFILLNESS: "Date Of Illness",
  INSUREDID: "Insured ID",
  DIAGNOSISCODES: "Diagnosis Codes",
  FACILITYNAME:	"Facility Name",
  FACILITYADDRESS: "Facility Address",
  TELEPHONE:	"Telephone",
  STATUS:	"Status",
  PATIENTSTATUS: "Patient Status",
  PATIENTGENDER: "Patient Gender",
  PATIENTTELEPHONENUM: "Patient Telephone Number",
  PATIENTPHONE: "Patient Telephone Number",
  PATIENTDOB: "Patient DOB",
  PATIENTADDRESS: "Patient Address",
  INSUREDDOB: "Insured DOB",
  DATEOFBIRTH: "Date Of Birth",
  RELATIONSHIPTOINSURED: "Relationship To Insured",
  RELATIONSHIP: "Relationship",
  LOCALUSE: "Local Use",
  patientRelationshipToInsured: "Patient Relationship To Insured",
  patientRelation: "Patient Relationship To Insured",
  NAMEANDFACILITYADDRESS: "Name And Facility Address",
  NAMEANDFACILITY: "Name And Facility Address",
  DAYSORYUNITS: "Days Or Units",
  ADDRESS: "Address",
  NAMEANDDRESS: "Name And Address",
  NAME: "Name",
  PLACEOFSERVICE: "Place Of Service",
  "place of service": "Place Of Service",
  PlaceOfService: "Place Of Service",
  "place of service": "Place Of Service",
  "place_of_service": "Place Of Service",
  DAYSUNITS: "Days Or Units",
  DAYSORUNIT: "Days Or Units",
  DAYSOUNITS: "Days Or Units",
  MODIFIER:	"Modifier",
  CODEANDMODIFIERS: "Modifier",
  FROMDATE:	"From Date",
  SERVICES:	"Services",
  DIAGNOSISPOINTER: "Diagnosis Pointer",
  diagnosis_pointer: "Diagnosis Pointer",
  EPSDT:	"EPSDT",
  SUPPLIES:	"Supplies",
  ProcedureCodes: "Procedure Codes",
  UNUSUALCIRCUMSTANCES:	"Unusual Circumstances",
  PROCEDUREDESCRIPTION:	"Procedure Description",
  COB:	"COB",
  TODATE:	"To Date",
  EMG:	"EMG",
  RESERVEDLOCALUSE:	"Reserved For Local Use",
  RESERVEDFORLOCAUE: "Reserved For Local Use",
  OTHERINSURANCEPLANS: "Other Insurance Plans",
  INSUREDPOLICYBIRTHDATE: "Insured Policy Birth Date",
  RESERVELOCALUSE:	"Reserved For Local Use",
  RESERVELOCAL:	"Reserved For Local Use",
  RESERVEDLOCAL: "Reserved For Local Use",
  RESERVEDFORLOCALUSE: "Reserved For Local Use",
  reserved_for_local_use: "Reserved For Local Use",
  RESERVEDFORLOCAL: "Reserved For Local Use",
  SIMILARILLNESS: "Similar Illness",
  anotherHealthBenefitPlan: "Another Health Benefit Plan",
  DATEOFFIRSTSYMPTOM: "Date Of First Symptom",
  PREVIOUSILLNESS: "Previous Illness",
  REFERRINGPHYSICIANNAME:	"Referring Physician Name",
  "Referring Physician": "Referring Physician Name",
  NAMEOFREFFERINGPHYSICIAN: "Referring Physician Name",
  NAMEREFERRINGPHYSICIAN: "Referring Physician Name",
  name_of_referring_physician: "Referring Physician Name",
  NAMEANDADDRESS: "Name And Address",
  nameAndAddress: "Name And Address",
  REFERRINGPHYSICIAN: "Referring Physician",
  FIRSTDATEOFSIMLLARILLNESS: "First Data Of Similar Illness",
  DATEOFCURRENTILLNESS:	"Date Of Current Illness",
  DATEOFSILIARILLNESS: "Date Of Similar Illness",
  DATEOFCURRENTILLNESSINJURYPREGNANCY: "Date Of Current Illness",
  date_of_current_illness_or_injury_or_pregnancy: "Date Of Current Illness",
  DATEOFSIMILARILLNESS:	"Dates Of Similar Illness",
  FIRSTDATEOFILLNESSIMILAR: "First Date Of Similar Illness",
  DATEOFCURRENTILLNESSORINJURYORPREGNANCY:"Date Of Current Illness Or Injury Or Pregnancy",
  REFERRINGPHYSICIANNUMBER: "Referring Physician Number",
  REFERRINGPHYSICIANID: "Referring Physician ID",
  id_of_referring_physician: "Referring Physician ID",
  nameOfReferringPhysician: "Name Of Referring Physician",
  idOfReferringPhysician: "ID Of Referring Physician",
  IDOFREFFERINGPHYSICIAN: "ID Of Referring Physician",
  EMERGENCYMEDICALGROUPCODE: "Emergency Medical Group Code",
  COORDOFBENEFITS: "Co Ord Of Benefits",
  EMERGENCYINDICATOR: "Emergency Indicator",
  emergancyIndicator: "Emergency Indicator",
  cobIndicator: "cob Indicator",
  giveFirstDateIfPatientHadSimilarIllness: "Give First Date Of Patient Had Similar Illness",
  FEDERALTAXID:	"Federal Tax ID",
  ISSSNSELECTED:	"Is SSN Selected",
  ISENNUMBERSELECTD: "Is ENN Selected",
  ISSSNSELECED: "Is SSN Selected",
  ISSSNSELECTD: "Is SSN Selected",
  SSNSELECTED: "Is SSN Selected",
  EINSELECTED: "Is EIN Selected",
  ISSSN: "Is SSN Selected",
  ISEINSELECTED:	"Is EIN Selected",
  "is SSN selected": "Is EIN Selected",
  ISSPNSELECTED: "Is SPN Selected",
  "ISEIN NUMBERSELECTED":	"Is EIN Selected",
  "is EIN Number selected": "Is EIN Selected",
  isEINNumberSelected: "Is EIN Number Selected",
  is_ein_number_selected: "Is EIN Number Selected",
  "is_ssn_selected": "Is SSN Selected",
  federalTaxIDNumber: "Federal Tax ID Number",
  "Federal Tax ID number": "Federal Tax ID Number",
  "federal_tax_id_number": "Federal Tax ID Number",
  ISEIN: "Is EIN Selected",
  "IS EIN": "Is EIN Selected",
  CHARGES: "Charges",
  gender: "Gender",
  Gender: "Gender",
  INSURANCEPLANNAME: "Insurance Plan Name",
  fromDateOfServices: "From Date Of Services",
  FromDateOfService: "From Date Of Services",
  from_date_of_services: "From Date Of Services",
  AMOUNTPAID:	"Amount Paid($)",
  BALANCEDUE: "Balance Due($)",
  daysOrUnits: "Days Or Units",
  "days or units": "Days Or Units",
  "days_or_units": "Days Or Units",
  UNITSDAYS: "Days Or Units",
  emd: "EMD",
  DATEOFCURRENTILLNESSINJURYORPREGNANCY: "Date Of Current Illness/Injury/Pregnancy",
  
  epdtFamilyPlan: "EPDT Family Plan",
  toDateOfService: "To Date Of Service",
  to_date_of_service: "To Date Of Service",
  "From date of services": "From Date Of Services",
  "procedure description": "Procedure Description",
  cod: "COD",
  CODRELATEDSERVICES: "COD Related Services",
  DATEOFPRIORSIMILARILLNESS: "Date Of Prior Similar Illness",
  TOTALCHARGES: "Total Charges($)",
  "TOTAL CHARGES": "Total Charges($)",
  TOTAL_CHARGES: "Total Charges($)",
  "TOTAL-CHARGES": "Total Charges($)",
  "TOT CHARGE": "Total Charges($)",
  TOTALCHARGE: "Total Charges($)",
  PROCEDURECODE: "Procedure Code",
  PROCEDURECODES: "Procedure Code",
  PROCEDURE_CODE: "Procedure Code",
  PROCEDURE_CODES: "Procedure Code",
  proceduresCode: "Procedure Code",
  "procedures code": "Procedure Code",
  "procedures_code": "Procedure Code",
  explainUnusualCircumstances: "Explain Unusual Circumstances",
  EXPLANATIONOFUNUSUALCIRCUMSTANCES: "Explain Unusual Circumstances",
  "explain unusual circumstances": "Explain Unusual Circumstances",
  "explain_unusual_circumstances": "Explain Unusual Circumstances",
  "procedure description": "Procedure Description",
  proceduresDescription: "Procedure Description",
  "procedure_description": "Procedure Description",
    
  OTHERHEALTHBENEFITPLAN: "Other Health Benefit Plan",
  INSURANCEPLANNAMESCHOOLNAME: "Insurance Plan Name",
  INSURANCEPLANNAMEORACHOOLNAME: "Insurance Plan Name",
  INSUREDPOLICYGROUPORFECAUMBER: "Insurance Policy Group Or Feca Number",
  INSURANCEPLANNAMEORUNIVERSITYNAME: "Insurance Plan Name Or University Name",
  INSUREDPOLICYGROUPORFECA: "Insurance Policy Group Or Feca Number",
  INSUREDPOLICYGROUPORFECANO: "Insured Policy Group Or FecaNo",
  INSUREDPOLICYGROUPORNUMBER: "Insured Policy Group Or Number",
  INSUREDPOLICYGROUPFECANUM: "Insured Policy Group Or Number",
  ZIPCODE: "Zip Code",
  CITY: "City",
  STATE: "State",
  IDREFERRINGPHYSICIAN: "Referring Physician ID",
  EPSDTFAMILYPLAN: "EPSDT Family Plan",
  EPSDTFAMILY: "EPSDT Family Plan",
  epsdtFamilyPlan: "EPSDT Family Plan",
  EPSDTFamilyPlan: "EPSDT Family Plan",
  epsdt_family_plan: "EPSDT Family Plan",
  CODRESERVED: "COD Reserved",
  PATIENTNAME: "Patient Name",
  PATIENTRELATIONSHIP: "Patient Relationship",
  SIMILARILLNESSDATE: "Similar Illness Date",
  DATESOFSIMILARILLNESS: "Similar Illness Date",
  FIRSTDATESIMILARILLNESS: "First Date Of Similar Illness",
  FIRSTDATEOFCURRENTILLNESSSYMPTOM: "First Date Of Current Illness",
  FIRSTDATEOFILLNESS: "First Date Of Illness",
  DATEOFFIRSTILLNESS: "Date Of First Illness",
  OTHERPLAN: "Other Plan",
  UNUSUALCIRCUMSTANCESEXPLANATION: "Unusual Circumstances Explanation",
  POLICYGROUP: "Policy Group",
  FIRSTDATE: "First Date Of Patient Having Same Condition",
  date_of_birth: "Date Of Birth",
  patient_relationship_to_insured: "Patient Relationship To Insured",
  insured_number: "Insured Number",
  insurance_plan_name_or_school_name: "Insurance Plan Name Or School Name",
  insurancePlanNameOrSchoolName: "Insurance Plan Name Or School Name",
  OTHERINSURENCE: "Other Insurance",
  INSUREDEMPLOYERSCHOOLNAME: "Insurance Plan Name Or School Name",
  EMPLOYERORSCHOOLNAME: "Employer Or School Name",
  INSUREADDATEOFBIRTH: "Insured Date Of Birth",
  INSURANCEPLAN: "Insurance Plan Name Or School Name",
  SCHOOLNAME: "Insurance Plan Name Or School Name",
  INSURANCEPROVIDENAMEORSCHOOLNAME: "Insurance Provider Name Or School Name",
  INSURANCEPLANORSCHOOL: "Insurance Provider Name Or School Name",
  OTHERINSUANCEPROVIDER: "Other Insurance Provider",
  INSURANCEPLANDORSCHOOLNAME: "Insurance Provider Name Or School Name",
  InsurancePlanNameOrSchoolName: "Insurance Plan Name Or School Name",
  insurancePlanNameOrSchoolName: "Insurance Plan Name Or School Name",
  insurancePlanNameOrSchoolName: "Insurance Plan Name Or School Name",
  any_other_health_benefit_plan: "Any Other Health Benefit Plan",
  insured_policy_group_or_feca_number: "Insured Policy Group Or Feca Number",
  INSUREDPOLICYGROUPFECA: "Insured Policy Group Or Feca Number",
  INSUREDPOLICYGROUPORCFACE: "Insured Policy Group Or Feca Number",
  PRIORILLNESS: "Prior Illness",
  FIRSTILLNESSDATE: "First Illness Date",
  FIRSTDATEILLNESS: "First Illness Date",
  give_first_date_if_patient_had_similar_illness: "Give First Date If Patient Had Similar Illness",
  "name and address of facility where service was rendered or hospital where treatement was done": "Name And Address Of Facility Where Service Was Rendered Or Hospital Where Treatment Was Done",
  nameAndAddressOfFacilityWhereServiceWasRendered: "Name And Address Of Facility Where Service Was Rendered",
  name_and_address_of_facility_where_service_was_rendered: "Name And Address Of Facility Where Service Was Rendered",
  "amount paid": "Amount Paid($)",
  "amount_paid": "Amount Paid($)",
  "balance due": "Balance Due($)",
  "balance_due": "Balance Due($)",
  FIRSTDATEPATIENTHADSAMECONDITION: "First Date Of Patient Having Same Condition",
  INSUREDDOBGENDER: "Insured DOB And Gender",
  ILLNESSDATE: "Illness Date",
  
  PAYMENT_INSTRUCTION_FORM: "Payment Instruction Form",
  STATEMENT_DATE: "Statement Date",
  POLICY_NUMBER: "Policy Number",
  POLICY_ON_THE_LIFE_OF: "Policy On The Life Of",
  POLICY_OWNER: "Policy Owner",
  RETURNED_THE_POLICY_SELECTED: "Returned The Policy Selected",
  PAYMENT_DETAILS: "Payment Details",
  BANK_NAME_AND_ADDRESS: "Bank Name And Address",
  ACCOUNT_HOLDERS_NAME: "Account Holders Name",
  ACCOUNT_NUMBER: "Account Number",
  BANK_SORT_CODE: "Bank Sort Code",
  SIGNED_FULL_NAME: "Signed Full Name",
  SIGNED_DATE: "Signed Date",
  LOST_POLICY_FORM: "Lost Policy Form",
  STATEMENT_DATE: "Statement Date",
LOST_POLICY_FORM_SIGNED: "Lost Policy Form Signed",
FULL_NAME: "Full Name",
DATE: "Date",
LOST_POLICY_FORM_WITNESSED_BY: "Lost Policy Form Witnessed By",
FULL_NAME_OF_WITNESS: "Full Name Of Witness",
ADDRESS_OF_WITNESS: "Address Of Witness",
OFFICIAL_STAMP: "Official Stamp",
"DAY-TIME_TELEPHONE_NUMBER_OF_WITNESS": "Day-Time Telephone Number Of Witness",
OCCUPATION_OF_WITNESS: "Occupation Of Witness",
Approver1_Focuses_On: "Verifier1 Focuses On",
Approver2_Focuses_On: "Verifier2 Focuses On",
Verifier1_Focuses_On: "Verifier1 Focuses On",
Verifier2_Focuses_On: "Verifier2 Focuses On",
Additional_Information: "Additional Information",
Suggested_Action_Items: "Suggested Action Items",
Detailed_Summary: "Detailed Summary",
};