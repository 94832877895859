import React, { useEffect, useState, useRef } from 'react';
import '../../index.css';
import axios from 'axios';
import { config } from "../../config";
import { FaTimes, FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AWS, { AutoScaling } from 'aws-sdk';
// import Summary from './summaryFolder/Summary';
// import { useVariable } from '../../Context';
import { useSharedState } from '../../SharedStateContext';
// import { useVerifiedState } from '../../SharedStateContext';
import { S3 } from 'aws-sdk';

import  { useChatbotState } from '../../SharedStateContext';

const Overallresult = () => {
    const { sharedValues } = useSharedState();
    const { chatbotdetails } = useChatbotState();
    // const { verifiedValues } = useVerifiedState();
    // const policy_num = verifiedValues.policynum;
    // const policy_num = "L2065777";
    const extracted_data = sharedValues.docdetails;
    // const policy_num = sharedValues.policynum;
    const policy_num = extracted_data.PAYMENT_INSTRUCTION_FORM.POLICY_NUMBER;
    console.log("POLICY NUMBER IN 3RD PAGE",policy_num);
    // const history_result = verifiedValues.history_details;
    // const checklist_result = verifiedValues.checklist_details;
    
    const [verification_result, setVerification_result] = useState(null);
    const [history_result, setHistory_result] = useState(null);
    const [checklist_result, setChecklist_result] = useState(null);
    
    console.log("VERIFICATION RESULT",verification_result);
    const { chatbotValue, setChatbotValue } = useChatbotState();
    const docData = sharedValues.docdetails;
    // const itemobj = docData.message[1].Item;
    const uploadedfilename = sharedValues.uploadedfilename;
    const doc_category = sharedValues.doccategory;
    const claim_category = sharedValues.claimcategory;
    const redPercent = sharedValues.unfilledpercent;
    const redPercentage = parseFloat(redPercent.replace('%', ''));
    const greenPercentage = 100 - redPercentage;
    const claimID = sharedValues.claimid;
    const fileUploaded = sharedValues.uploadedfilename;
    console.log("UPLOADED FILE NAME", fileUploaded);
    console.log("CLAIMID", claimID)
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [inputValue, setInputValue] = useState(chatbotdetails.questionList);
    const [inputList, setInputList] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [clickedDocument, setClickedDocument] = useState(null);
    const [fileList, setFileList] = useState(null);
    const navigate = useNavigate();
    const [showSampleDocumentsPopup, setShowSampleDocumentsPopup] = useState(false);
    const [viewDownloadClicked, setViewDownloadClicked] = useState(false);
    const [chatbotresponsefromlambda, setChatbotresponsefromlambda] = useState(chatbotdetails.answerList);
    const [historicalClaim, setHistoricalClaim] = useState(chatbotdetails.historicData);
    const [claimSpecificfiles, setClaimSpecificfiles] = useState(null);
    
    const [showsendText, setShowsendText] = useState(false);
    const [showrejectText, setShowrejectText] = useState(false);
    // const [clickedDocument, setClickedDocument] = useState(null);
    const location = useLocation();
    console.log("REVERSE", location.state?.data);
    // // Parse the URL parameter to extract the data
    // const searchParams = new URLSearchParams(location.search);
    // const encodedData = searchParams.get('data');

    // Decode the data and parse the JSON
    // const receivedData = JSON.parse(decodeURIComponent(encodedData));
    const { receivedData } = location.state || {};


 const handlerejectclick = () => {
    setShowrejectText(true);
    setShowsendText(false);
  };
const handlesendclick = () => {
    setShowsendText(true);
    setShowrejectText(false);
  };
    
    const handleDocumentClick = (document, index) => {
    // setClickedDocumentIndex(index);
    // setClickedDocument((prevDocument) => (prevDocument === document ? null : document));
    setClickedDocument(uploadedfilename);
    // setShowDocumentDetails(true); // Toggle visibility
    // setDocname((prevDocument) => (prevDocument === document ? null : document.document_name));
    // setClicked("clicked");
   
    // setClickedDocumentForPreview();
  };
    // const data = useSelector(state => state.data);
    console.log("DATA", receivedData);
    // json data and related functions- BEGIN
    
     const parsedKey = (inputkey) => {
        const matchingKey = Object.keys(config).find((key) =>
          key.toLowerCase() === inputkey.toLowerCase()
        );
        if (matchingKey) {
          // console.log("ORIGINAL",inputkey);
          // console.log("CONVERTED",config[matchingKey]);
          return(config[matchingKey]);
          
        } else {
          return(inputkey);
        }
      };

    // json data and related functions- END
    const redirectToMainPage = () => {
      // Redirect to the main page
      // const test = "TEST";
      navigate('/components/DocumentDetails');
    };

  // 3 Lambdas call (verify,history and checklist)
  useEffect(() => {
const verifylambda = async () => {
  console.log("FIRST LINE OF STATEMENT");
    const verify_extract = await axios.get('https://109bsrkzi1.execute-api.us-east-1.amazonaws.com/dev/claimassistverify', {
              params: {
        "claimid" : claimID,
    "policynumber": policy_num
                 
              }
    }
    );
  
  console.log("VERIFICATION LAMBDA RESPONSE",verify_extract.data);
  console.log("VERIFICATION LAMBDA RESPONSE TYPE",typeof verify_extract.data);
  // setVerifiedValue('verify_details',verify_extract.data);
  setVerification_result(verify_extract.data);
};
 const historylambda = async () => {
  console.log("FIRST LINE OF HISTORY STATEMENT");
    const history_extract = await axios.get('https://109bsrkzi1.execute-api.us-east-1.amazonaws.com/dev/claimassisthistory', {
              params: {
        "claimid" : claimID,
    "policynumber": policy_num,
    "claimtype" : "SURRENDER"
              }
    }
    );
  
  console.log("HISTORY LAMBDA RESPONSE",history_extract.data);
  console.log("HISTORY LAMBDA RESPONSE TYPE",typeof history_extract.data);
  // setVerifiedValue('history_details',history_extract.data);
  setHistory_result(history_extract.data);
};
 
 const checklistlambda = async () => {
  console.log("FIRST LINE OF checklist STATEMENT");
    const checklist_extract = await axios.get('https://109bsrkzi1.execute-api.us-east-1.amazonaws.com/dev/claimassistchecklist', {
              params: {
        "claimid" : claimID,
    "policynumber": policy_num,
    "claimtype" : "SURRENDER"
              }
    }
    );
  
  console.log("CHECKLIST LAMBDA RESPONSE",checklist_extract.data);
  console.log("CHECKLIST LAMBDA RESPONSE TYPE",typeof checklist_extract.data);
  console.log("CHECKLIST LAMBDA RESPONSE TYPE",typeof checklist_extract.data['Procedural Checks']);
  
  // setVerifiedValue('checklist_details',checklist_extract.data);
  setChecklist_result(checklist_extract.data);
};
 
 
      verifylambda();
      historylambda();
      checklistlambda();
 }, []);

   
   
   
const rectangleStyle = {
  borderTop: '1px solid #000000',
  borderBottom: '2px solid #000',
  borderLeft: '1px solid #000',
  borderRight: '2px solid #000',
  marginRight: '15px',
  // marginLeft: '-5px',
  // marginTop: -3,
  display: 'flex',
  width: '7px',
  height: '7px',
  flexShrink: 0,
  marginTop: '0.3em',
};
const containerStyle = {
  display: 'flex',
};

let redwidth;
if (redPercentage < 10){
  redwidth = '23';
}
else{
  redwidth = redPercentage;
}
const containerStyle1 = {
    display: 'flex',
    height: '30px',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
    width: '140px'
  };

  const barStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    transition: 'width 0.5s ease-in-out'
  };

  const greenStyle = {
    ...barStyle,
    backgroundColor: 'green',
    width: `${greenPercentage}%`
  };

  const redStyle = {
    ...barStyle,
    backgroundColor: 'red',
    width: redwidth
  };

  const labelStyle = {
    position: 'absolute'
  };



console.log("HH",historicalClaim)
let claimLines;
if (historicalClaim.includes("\n")) {
claimLines = historicalClaim.split("\n");
}
else{
  claimLines = historicalClaim;
}
// claimLines = historicalClaim.split("\n");

const outerRectangleStyle = {
    border: '2px solid black',
    padding: '10px',
    
    // width: '100%',
    // margin: 'auto', // Center the outer container
    marginTop: '3em',
    marginLeft: '5em',
    marginRight: '5em',
    borderRadius: '25px',
  };

  const rowStyle = {
    display: 'flex',
    // width: '100%'
    
    
  };

  console.log("CHECKLIST RESULT TYPE",typeof checklist_result); {/*object*/}
  console.log("CHECKLIST RESULT",checklist_result);
  if (checklist_result != null){
  console.log("CHECKLIST VALUES TYPE",typeof checklist_result['Procedural Checks']);{/*object*/}
  console.log("CHECKLIST VALUES",Object.keys(checklist_result['Procedural Checks']));
  
  }
  const DisplayObject = ({ data }) => {
  return (
    <div>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
          <div style={{ fontWeight: 'bold', marginRight: '10px' }}>{key}</div>
          <div>{typeof value === 'object' ? JSON.stringify(value) : value.toString()}</div>
        </div>
      ))}
    </div>
  );
};
console.log("VERIFICATION RESULT TYPE",typeof verification_result);
const successfulKeyword = 'Successful Messages:';
  const unsuccessfulKeyword = 'Unsuccessful Messages:';
  let successfulLines;
  let unsuccessfulLines;
  // Split the message into successful and unsuccessful parts
  if (verification_result !== null) {
    const successfulMessageStart = verification_result.indexOf(successfulKeyword);
    const unsuccessfulMessageStart = verification_result.indexOf(unsuccessfulKeyword);
  
    const successfulMessage = verification_result.substring(successfulMessageStart + successfulKeyword.length, unsuccessfulMessageStart).trim();
    const unsuccessfulMessage = verification_result.substring(unsuccessfulMessageStart + unsuccessfulKeyword.length).trim();
  
   // Split messages by period and newline characters
     successfulLines = successfulMessage.split('\n').filter(line => line.trim() !== '');
     unsuccessfulLines = unsuccessfulMessage.split('\n').filter(line => line.trim() !== '');
}
// History lambda
console.log("HISTORY LAMBDA",typeof history_result);
let data;
 if (history_result !== null) {
  data = JSON.parse(history_result);
 }

  return (
 <>
  {/* HEADER SECTION - BEGIN */}
<div style={{ overflow: 'hidden', position: 'fixed',top: '0', width: '100%', zIndex: '1000' }}>
  <div  style={{ overflow: 'hidden',backgroundColor: '#FFFFFF', padding: '-1px', color: '#0F5FDC', display: 'flex', justifyContent: 'space-between', alignItems: 'center',borderBottom: '1px solid #DBC5FF' }}>
    <div  style={{ display: 'flex',overflow: 'hidden',color:'#0F5FDC',fontSize: '7px',margin: 'auto' }}>
      <h1>HCLTech</h1>
    </div>
  </div>
</div>
{/* HEADER SECTION - END */}
<div style={outerRectangleStyle}>
      <div style={rowStyle}>
        {/* <div style={rectangleStyle}> */}
        {/* <div style={{flex: '1',border: '1px solid transparent',margin: '5px',padding: '10px',textAlign: 'left',display: 'flex',flexDirection: 'column', borderRadius: '15px'}}> */}

<div style={{flex: '1',border: '1px solid black',margin: '5px',padding: '10px',textAlign: 'left',color: '#000000',display: 'flex',flexDirection: 'column',borderRadius: '15px',height:'100%'}}>
        {/* <div style={{border: '2px solid black',padding: '10px',marginTop: '3em',marginLeft: '5em',marginRight: '5em',borderRadius: '25px',background:'white'}}> */}
        <b style={{ textAlign: 'left', display: 'block', marginTop: '0em' ,color:'#FFFFFF',fontSize:'16px'}}>
  
 <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
  <span style={{ textAlign: 'left' }}>Claim Id: {claimID}<br /><br /></span>
  <span style={{ textAlign: 'right' }}>Claim Category: {claim_category}<br /><br /></span>
</div>
  
  Uploaded Documents:
</b>
<br/>
<table style={{ width: '100%', fontWeight: 'bold', borderCollapse: 'collapse', border: '1px solid black' }}>
  <thead>
    <tr style={{background:'#87CEEB'}}>
      <th style={{ textAlign: 'left', padding: '10px 20px', border: '1px solid black' }}>Document Name</th>
      <th style={{ textAlign: 'left', padding: '10px 20px', border: '1px solid black' }}>Document Type</th>
    </tr>
  </thead>
  <tbody>
    <tr style={{background:'white'}}>
      <td style={{ width:'60%',padding: '10px 20px', border: '1px solid black' }}>
        <a
          href="#"
          onClick={() => handleDocumentClick(uploadedfilename)}
          style={{ textDecoration: 'underline', fontWeight: 'bold', color: 'green',backgroundColor:'white' }}
        >
          {uploadedfilename}
        </a>
        <div style={{ display: 'inline-block', marginLeft: '10px' }}>
          <div style={{ display: 'flex', width:'150px',alignItems: 'center' }}>
            <div style={greenStyle}>
              {greenPercentage > 5 && <span>{greenPercentage}%</span>}
            </div>
            <div style={redStyle}>
              {redPercentage > 3 && <span>{redPercentage}%</span>}
            </div>
          </div>
        </div>
      </td>
      <td style={{ width:'40%',padding: '10px 20px', border: '1px solid black' }}>{doc_category}</td>
    </tr>
  </tbody>
</table>


        </div>
        </div>
        <br/>

       <div style={{display:'flex'}}> 
        {/* <div style={rectangleStyle}> */}
       <div style={{flex: '1',border: '1px solid black',margin: '5px',padding: '10px',textAlign: 'left',color: '#000000',display: 'flex',flexDirection: 'column',borderRadius: '25px',height:'100%',background:'white'}}>
    {verification_result && (
     <div>
      <b>{successfulKeyword}</b>
      <ul className="message-list">
        {successfulLines.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ul>
      <b>{unsuccessfulKeyword}</b>
      <ul className="message-list">
        {unsuccessfulLines.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ul>
    </div>)}
        </div>
       <div style={{width:'100px',flex: '1',border: '1px solid transparent',margin: '5px',padding: '10px',textAlign: 'left',color: '#000000',display: 'flex',flexDirection: 'column',background:'white'}}>
       {history_result && (
       <div>
      {Object.keys(data).map((key) => (
        <div key={key}>
          <strong>{parsedKey(key)}: </strong>
          {Array.isArray(data[key]) ? data[key].join(', ') : data[key]}
       
          <br/>
          <br/>
        </div>
      ))}
    </div>
       )}
        </div>
        </div>
               <div style={{borderRadius: '25px',flex: '1',border: '1px solid transparent',margin: '5px',padding: '10px',textAlign: 'left',color: '#000000',display: 'flex',flexDirection: 'column',background:'white'}}>
     {/*<DisplayObject data={checklist_result['Procedural Checks']} />*/}
    {/* {checklist_result ? <DisplayObject data={checklist_result['Procedural Checks']} /> : null}*/}
     {checklist_result && (
          <div>
            <div>
      <table border="1" style={{borderCollapse: 'collapse',border: '1px solid black',padding: '8px',backgroundColor: '#f2f2f2'}}>
        <thead style={{backgroundColor: '#87CEEB'}}>
          <tr>
            <th>Question</th>
            <th>Yes</th>
            <th>No</th>
            <th>N/A</th>
          </tr>
        </thead>
        <tbody>
          {checklist_result['Procedural Checks'].map((item, index) => {
            const questionKey = Object.keys(item)[0];
            const question = item[questionKey];
            return (
              <tr key={index} style={{border: '1px solid black',padding:'8px'}}>
                <td style={{border: '1px solid black',padding:'8px'}}>{question.Question}</td>
                <td style={{border: '1px solid black',padding:'8px'}}>{question.Yes}</td>
                <td style={{border: '1px solid black',padding:'8px'}}>{question.No}</td>
                <td style={{border: '1px solid black',padding:'8px'}}>{question['N/A']}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
            
          </div>)}

          </div>
          
           <div style={{ fontFamily:'Montserrat',display: 'flex', justifyContent: 'space-between', marginTop: '1em', marginLeft: '0em', marginRight: '0em' }}>
        {/* <button style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }} onClick={redirectToMainPage}><img style={{ width: '110px', height: '50px'}} src="/backyellow.png"/> </button> */}
        <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }} onClick={handlerejectclick}>Reject </button>
          {showrejectText && <p style={{color:'white'}}>Claim Rejected!</p>}
       
        <button style={{ width: '200px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }}> <Link to="./VeriferDashboard/VeriferDashboard" style={{textDecoration:'none',color:'white'}}>Send To Authorizer&nbsp;&nbsp;{">"}{">"}</Link> </button>
          </div>
          
    </div>
    
    <div style={{ fontFamily:'Montserrat',display: 'flex', justifyContent: 'space-between', marginTop: '1em', marginLeft: '0em', marginRight: '0em' }}>
        <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }} onClick={redirectToMainPage}>{"<"}{"<"}&nbsp;&nbsp;Back </button>
      
   </div>
  
    <div className="footer" style={{ marginTop:'1em',position:'sticky',bottom:0, left: 0, width: '100%', height: 20, background: 'white', borderTop: '1px #DBC5FF solid', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <span style={{color: '#171719', fontSize: '10px', fontFamily: 'Montserrat', fontWeight: '500', letterSpacing: '0.28px', wordWrap: 'break-word'}}>© 2023 All Rights Reserved to </span>
  <span style={{color: '#0F5FDC', fontSize: '10px', fontFamily: 'Montserrat', fontWeight: '500',letterSpacing: '0.28px', wordWrap: 'break-word'}}>HCLTech</span>
  </div>
  </>
    
  );
};

export default Overallresult;
