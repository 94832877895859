import React, { useContext , useEffect, useState, useRef } from 'react';
import '../index.css';
import axios from 'axios';
import { FaTimes, FaCheck } from 'react-icons/fa';
import {  useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import S3FilePreview from './S3FileViewer';
import { useSharedState } from '../SharedStateContext';
import { config } from "../config";
// import { useVerifiedState } from '../SharedStateContext';
// import Documentdetails from "";
const Documentdetails = () => {
  // const { verifiedValue, setVerifiedValue } = useVerifiedState();
const { sharedValues } = useSharedState();
const receivedData = sharedValues.docdetails;
console.log("RECEIVED DATA",receivedData);
const claimID = sharedValues.claimid;
// const claimID = "CL17194319"
console.log("CLAIM  ID",claimID);
const doc_category = sharedValues.doccategory;
const claim_category = sharedValues.claimcategory;
const redPercent = sharedValues.unfilledpercent;
const redPercentage = parseFloat(redPercent.replace('%', ''));
const greenPercentage = 100 - redPercentage;
const fileDisplayList = sharedValues.filelist; 
const uploadedfilename = sharedValues.uploadedfilename;
console.log("UPLOADED FILE NAME",uploadedfilename);
console.log("UPLOADED FILE NAME TYPE",typeof uploadedfilename);
// state variables for checking file existence in s3
// const [filePath, setFilePath] = useState('');
const [isPrimaryPathChecked, setIsPrimaryPathChecked] = useState(false);
const [clicked, setClicked] = useState(null);
const [clickedDocument, setClickedDocument] = useState(null);
const [clickedDocumentIndex, setClickedDocumentIndex] = useState(null);

console.log("TRANSFERLIST",fileDisplayList);
const lambdajson = receivedData;


// console.log("POLICY NUMBER",lambdajson.LOST_POLICY_FORM.POLICY_NUMBER);
// const policy_num = receivedData.PAYMENT_INSTRUCTION_FORM.POLICY_NUMBER;
// setVerifiedValue('policynum',policy_num);

const handleDocumentClick = (document, index) => {
    setClickedDocumentIndex(index);
    // setClickedDocument((prevDocument) => (prevDocument === document ? null : document));
    setClickedDocument(uploadedfilename);
    // setShowDocumentDetails(true); // Toggle visibility
    // setDocname((prevDocument) => (prevDocument === document ? null : document.document_name));
    setClicked("clicked");
   
    // setClickedDocumentForPreview();
  };
const filePath=`claimassist/claimforms/${claimID}/${uploadedfilename}`;
const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <div
        style={{ fontWeight:'bold',position: 'relative',cursor: 'pointer', padding: '10px', border: '1px solid black', marginTop: '5px', background: '#87CEEB', fontFamily:'verdana' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {parsedKey(title)}
        <span style={{position: 'absolute',right: 0, top: '50%', transform: 'translateY(-50%)',color:'#DAA520' }}>▼</span>
      </div>
      {isOpen && <div style={{ padding: '10px', border: '1px solid black', borderTop: 'none' }}>{children}</div>}
    </div>
  );
};
let redwidth;
if (redPercentage < 10){
  redwidth = '23';
}
else{
  redwidth = redPercentage;
}
const containerStyle = {
    display: 'flex',
    height: '30px',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
    width: '140px'
  };

  const barStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    transition: 'width 0.5s ease-in-out'
  };

  const greenStyle = {
    ...barStyle,
    backgroundColor: 'green',
    width: `${greenPercentage}%`
  };

  const redStyle = {
    ...barStyle,
    backgroundColor: 'red',
    width: redwidth
  };

  const labelStyle = {
    position: 'absolute'
  };


const outerRectangleStyle = {
    border: '2px solid black',
    padding: '10px',
    
    // width: '100%',
    // margin: 'auto', // Center the outer container
    marginTop: '3em',
    marginLeft: '5em',
    marginRight: '5em',
    borderRadius: '25px',
  };

  const rowStyle = {
    display: 'flex',
    // width: '100%'
    
    
  };

  const rectangleStyle = {
    flex: '1',
    border: '1px solid black',
    margin: '5px',
    padding: '10px',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '25px',
    // background:'white'
  };
  const navigate = useNavigate();
  const redirectToMainPage = () => {
    // Redirect to the main page
    navigate('/Landing');
  };
  
  console.log("LAMBDA JSON TYPE",typeof lambdajson);
  // const extracted_obj = JSON.parse(lambdajson);
  // const extracted_obj = JSON.parse(receivedData);
  const extracted_obj = receivedData;
  console.log("EXTRACTED ATTR TYPE",typeof extracted_obj);
  // VERIFY - Lambda call
  
    const parsedKey = (inputkey) => {
        const matchingKey = Object.keys(config).find((key) =>
          key.toLowerCase() === inputkey.toLowerCase()
        );
        if (matchingKey) {
          // console.log("ORIGINAL",inputkey);
          // console.log("CONVERTED",config[matchingKey]);
          return(config[matchingKey]);
          
        } else {
          return(inputkey);
        }
      };

  
  return( 
  <>
  {/* HEADER SECTION - BEGIN */}
<div style={{ overflow: 'hidden', position: 'fixed',top: '0', width: '100%', zIndex: '1000' }}>
  <div  style={{ overflow: 'hidden',backgroundColor: '#FFFFFF', padding: '-1px', color: '#0F5FDC', display: 'flex', justifyContent: 'space-between', alignItems: 'center',borderBottom: '1px solid #DBC5FF' }}>
    <div  style={{ display: 'flex',overflow: 'hidden',color:'#0F5FDC',fontSize: '7px',margin: 'auto' }}>
      <h1>HCLTech</h1>
    </div>
  </div>
</div>
{/* HEADER SECTION - END */}
<div style={outerRectangleStyle}>
      <div style={rowStyle}>
        {/* <div style={rectangleStyle}> */}
        {/* <div style={{flex: '1',border: '1px solid transparent',margin: '5px',padding: '10px',textAlign: 'left',display: 'flex',flexDirection: 'column', borderRadius: '15px'}}> */}

<div style={{flex: '1',border: '1px solid black',margin: '5px',padding: '10px',textAlign: 'left',color: '#000000',display: 'flex',flexDirection: 'column',borderRadius: '15px',height:'100%'}}>
        {/* <div style={{border: '2px solid black',padding: '10px',marginTop: '3em',marginLeft: '5em',marginRight: '5em',borderRadius: '25px',background:'white'}}> */}
        <b style={{ textAlign: 'left', display: 'block', marginTop: '0em' ,color:'#FFFFFF',fontSize:'16px'}}>
  
 <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
  <span style={{ textAlign: 'left' }}>Claim Id: {claimID}<br /><br /></span>
  <span style={{ textAlign: 'right' }}>Claim Category: {claim_category}<br /><br /></span>
</div>
  Uploaded Documents:
  
</b>
<br/>
<table style={{ width: '100%', fontWeight: 'bold', borderCollapse: 'collapse', border: '1px solid black' }}>
  <thead>
    <tr style={{background:'#87CEEB'}}>
      <th style={{ textAlign: 'left', padding: '10px 20px', border: '1px solid black' }}>Document Name</th>
      <th style={{ textAlign: 'left', padding: '10px 20px', border: '1px solid black' }}>Document Type</th>
    </tr>
  </thead>
  <tbody>
    <tr style={{background:'white'}}>
      <td style={{ width:'60%',padding: '10px 20px', border: '1px solid black' }}>
        <a
          href="#"
          onClick={() => handleDocumentClick(uploadedfilename)}
          style={{ textDecoration: 'underline', fontWeight: 'bold', color: 'green',backgroundColor:'white' }}
        >
          {uploadedfilename}
        </a>
        <div style={{ display: 'inline-block', marginLeft: '10px' }}>
          <div style={{ display: 'flex',width:'150px',alignItems: 'center' }}>
            <div style={greenStyle}>
              {greenPercentage > 5 && <span>{greenPercentage}%</span>}
            </div>
            <div style={redStyle}>
              {redPercentage > 3 && <span>{redPercentage}%</span>}
            </div>
          </div>
        </div>
      </td>
      <td style={{ width:'40%',padding: '10px 20px', border: '1px solid black' }}>{doc_category}</td>
    </tr>
  </tbody>
</table>


        </div>
        </div>
        <br/>
       <div style={{display:'flex'}}> 
        {/* <div style={rectangleStyle}> */}
       <div style={{width:'100px',flex: '1',border: '1px solid black',margin: '5px',padding: '10px',textAlign: 'left',color: '#000000',display: 'flex',flexDirection: 'column',borderRadius: '25px',height:'100%'}}>
    
      <S3FilePreview key={filePath} fileName={filePath}/>
        </div>
       <div style={{width: '100%', flex: '1', border: '1px solid transparent', marginLeft: '1em', margin: '2px', padding: '1px', textAlign: 'left', color: '#000000', display: 'flex', flexDirection: 'column', background: 'white'}}>
  {Object.keys(extracted_obj).map((key) => (
    <Collapsible key={key} title={key}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {Object.keys(extracted_obj[key]).map((subKey) => (
            <tr key={subKey}>
              <td style={{ width:'50%', background: '#e6e6e6', fontWeight: 'bold', padding: '5px', wordWrap: 'break-word', border: '1px solid black' }}>
                {parsedKey(subKey)}
              </td>
              <td style={{ width:'50%',padding: '5px', wordWrap: 'break-word', border: '1px solid black' }}>
                {extracted_obj[key][subKey]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Collapsible>
  ))}
</div>
        </div>
        
      {/* </div> */}
    </div>
    <div style={{ fontFamily:'Montserrat',display: 'flex', justifyContent: 'space-between', marginTop: '1em', marginLeft: '0em', marginRight: '0em' }}>
        {/* <button style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }} onClick={redirectToMainPage}><img style={{ width: '110px', height: '50px'}} src="/backyellow.png"/> </button> */}
        <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }} onClick={redirectToMainPage}>{"<"}{"<"}&nbsp;&nbsp;Back </button>
        {/* <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }}><Link to="./nestedFolder/OverallResult" style={{textDecoration:'none',color:'white'}}>Next&nbsp;&nbsp;{">"}{">"}</Link></button> */}
        {/* console.log("FINALSEND",receivedData); */}
        {/* <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }}><Link to= {{ pathname: "./nestedFolder/OverallResult", state: { data: shareVariable } }}style={{textDecoration:'none',color:'white'}} >Next&nbsp;&nbsp;{">"}{">"}</Link></button> */}
        <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }}><Link to= {{ pathname: "./nestedFolder/OverallResult" }}style={{textDecoration:'none',color:'white'}} >Next&nbsp;&nbsp;{">"}{">"}</Link></button>

   </div>
  
    <div className="footer" style={{ marginTop:'1em',position:'sticky',bottom:0, left: 0, width: '100%', height: 20, background: 'white', borderTop: '1px #DBC5FF solid', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <span style={{color: '#171719', fontSize: '10px', fontFamily: 'Montserrat', fontWeight: '500', letterSpacing: '0.28px', wordWrap: 'break-word'}}>© 2023 All Rights Reserved to </span>
  <span style={{color: '#0F5FDC', fontSize: '10px', fontFamily: 'Montserrat', fontWeight: '500',letterSpacing: '0.28px', wordWrap: 'break-word'}}>HCLTech</span>
  </div>
  </>
    
);

};
export default Documentdetails;