import React, { createContext, useState, useContext,useEffect } from 'react';

const SharedStateContext = createContext();

export const SharedStateProvider = ({ children }) => {
  const [sharedValues, setSharedValues] = useState({
    docdetails: '',
    claimid: '',
    uploadedfilename:'',
    doccategory: '',
    filelist: [],
    claimcategory: '',
    policynum: '',
    unfilledpercent: '',
  });

  const setSharedValue = (name, value) => {
    setSharedValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <SharedStateContext.Provider value={{ sharedValues, setSharedValue }}>
      {children}
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => useContext(SharedStateContext);

// For storing verify lambda's response in second page
const VerifiedStateContext = createContext();

export const VerifyStateProvider = ({ children }) => {
  const [verifiedValues, setVerifiedValues] = useState({
    verify_details: '',
    history_data: '',
    checklist_details: '',
  });

  const setVerifiedValue = (name, value) => {
    setVerifiedValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <VerifiedStateContext.Provider value={{ verifiedValues, setVerifiedValue }}>
      {children}
    </VerifiedStateContext.Provider>
  );
};

export const useVerifiedState = () => useContext(VerifiedStateContext);

// For storing chatbot details in 3rd page

const ChatbotContext = createContext();

export const ChatbotProvider = ({ children }) => {
  const [chatbotdetails, setChatbotdetails] = useState({
    questionList: [],
    answerList: [],
    historicData: '',
    commonFiles: [],
    claimspecificFiles: []
   
    // Add more shared values as needed
  });

  const setChatbotValue = (name, value) => {
    setChatbotdetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

const [resetChatbot, setResetChatbot] = useState(false);

  useEffect(() => {
    if (resetChatbot) {
      console.log("RESETTED");
      setChatbotdetails({
        questionList: [],
        answerList: [],
        historicData: '',
        commonFiles: [],
    claimspecificFiles: []
        // Reset other state variables as needed
      });
      // Reset is complete, so set resetChatbot back to false
      setResetChatbot(false);
    }
  }, [resetChatbot]);

  // Function to reset chatbot state
  const resetChatbotState = () => {
    setResetChatbot(true);
  };

  return (
    <ChatbotContext.Provider value={{ chatbotdetails, setChatbotValue, resetChatbotState }}>
      {children}
    </ChatbotContext.Provider>
  );
};

export const useChatbotState = () => useContext(ChatbotContext);


// For storing  details in 4th page

const SummaryContext = createContext();

export const SummaryProvider = ({ children }) => {
  const [summarydetails, setSummarydetails] = useState({
    summary: ''
   
    // Add more shared values as needed
  });

  const setSummaryValue = (name, value) => {
    setSummarydetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

const [resetSummary, setResetSummary] = useState(false);

  useEffect(() => {
    if (resetSummary) {
      console.log("RESETTED");
      setSummarydetails({
       summary: '',
        // Reset other state variables as needed
      });
      // Reset is complete, so set resetChatbot back to false
      setResetSummary(false);
    }
  }, [resetSummary]);

  // Function to reset chatbot state
  const resetSummaryState = () => {
    setResetSummary(true);
  };

  return (
    <SummaryContext.Provider value={{ summarydetails, setSummaryValue, resetSummaryState }}>
      {children}
    </SummaryContext.Provider>
  );
};

export const useSummaryState = () => useContext(SummaryContext);
