import React, { useState, useEffect} from 'react';
import './VerifierDashboard.css'; // Import the CSS file
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
   const VerifierDashboard = () => {
       const [data, setData] = useState([]);

       useEffect(() => {
         fetchData();
       }, []);
   
    const fetchData = async () => {
         try {
           const response = await axios.get('https://3pey37cumu3bft2cqhcziwe3ea0jaqlp.lambda-url.us-east-1.on.aws/');
           console.log(response.data);
           setData(response.data);
         } catch (error) {
           console.error('Error fetching data:', error);
         }
       };
 const navigate = useNavigate();
 const redirectToMainPage = () => {
      // Redirect to the main page
      // const test = "TEST";
      navigate('/components/nestedFolder/OverallResult');
    };

     return (
        <div>
            <div style={{ overflow: 'hidden', position: 'fixed',top: '0', width: '100%', zIndex: '1000' }}>
                <div  style={{ overflow: 'hidden',backgroundColor: '#FFFFFF', padding: '-1px', color: '#0F5FDC', display: 'flex', justifyContent: 'space-between', alignItems: 'center',borderBottom: '1px solid #DBC5FF' }}>
                    <div  style={{ display: 'flex',overflow: 'hidden',color:'#0F5FDC',fontSize: '7px',margin: 'auto' }}>
                        <h1>HCLTech</h1>
                    </div>
                </div>
            </div>
            
           <div className="claims-table-container">
             <h1 className="heading">Verifiers Dashboard</h1>
             <table className="claims-table">
               <thead>
                 <tr>
                   <th>Claim Category</th>
                   <th>Claim Id</th>
                   <th>Policy Id</th>
                   <th>Submission Date</th>
                   <th>Status</th>
                 </tr>
               </thead>
               <tbody>
                 {data.map((claim, index) => (
                   <tr key={index}>
                     <td>{claim.claim_category}</td>
                     <td>{claim.claim_id}</td>
                     <td>{claim.claim_id}</td>
                     <td>{claim.claim_submitted_date}</td>
                     <td>
                       <span
                         className={`status-label ${
                           claim.claim_status.toLowerCase() === 'in-progress'
                             ? 'in-progress'
                             : claim.claim_status.toLowerCase() === 'completed'
                             ? 'completed'
                             : 'waiting'
                         }`}
                       >
                         {claim.claim_status}
                       </span>
                     </td>
                   </tr>
                 ))}
               </tbody>
             </table>
             
             <div style={{ fontFamily:'Montserrat',display: 'flex', justifyContent: 'space-between', marginTop:'0.5em',marginLeft: '0em', marginRight: '0em' }}>
         {/* <button style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer' }} onClick={redirectToMainPage}><img style={{ width: '110px', height: '50px' }} src="/backyellow.png"/> </button> */}

         <button style={{ width: '110px', height: '50px', borderRadius:'25px',border: 'green',background:'green', padding: '8px 12px', cursor: 'pointer',color:'white',fontSize:'16px' }} onClick={redirectToMainPage}>{"<"}{"<"}&nbsp;&nbsp;Back </button>

                </div>
             
           </div> 
       
            <div className="footer" style={{ marginTop:'1em',position:'sticky',bottom:0, left: 0, width: '100%', height: 20, background: 'white', borderTop: '1px #DBC5FF solid', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{color: '#171719', fontSize: '10px', fontFamily: 'Montserrat', fontWeight: '500', letterSpacing: '0.28px', wordWrap: 'break-word'}}>© 2023 All Rights Reserved to </span>
                <span style={{color: '#0F5FDC', fontSize: '10px', fontFamily: 'Montserrat', fontWeight: '500',letterSpacing: '0.28px', wordWrap: 'break-word'}}>HCLTech</span>
            </div>
        
        </div>
     );
   };

   export default VerifierDashboard;